import React from "react";
import { Helmet } from "react-helmet";
import { Link, withPrefix } from "gatsby";

import "../scss/main.scss";

const NotFound = () => {
    return (
        <>
            <Helmet>
                <title>404 | Teleport</title>
                <script src={withPrefix('inobounce.js')}></script>
            </Helmet>

            <section id="main-section" className="site-section">
                <div className="content-section not-found" style={ { '--width': '72rem' } }>
                    <h1 className="content-section__title">Page not found</h1>

                    <div className="content-section__text">
                        <p>Go <Link to="/" className="not-found__link">Home</Link></p>
                    </div>
                </div>
            </section>
        </>
    )
}

export default NotFound;